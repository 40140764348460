<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <div
      v-if="
        crypto ==
          'coJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLm1yb3JoYW4uY29tXC9wdWJsaWNcL2FwaVwvbG9naW4iLCJpYXQiOjE2NTU5Nzg2MDMsImV4cCI6MzU0ODEzODYwMywibmJmIjoxNjU1OTc4NjAzLCJqdGkiOiJTV2E3dk1OcmlkV3F4MTNCIiwic3ViIjoxMDAwMDAsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.jAlLm0LlTm4-n_2QKWgRLZmyvcroBri_I1md4v3UB_w'
      "
    >
      <section id="dashboard-analytics">
        <b-row class="match-height">
          <b-col cols="12">
            <b-card class="card-app-design pt-5 pb-5 mb-5" style="padding-bottom: 90px !important;">
              <h4 style="text-align: right;">
                <u>Total Responses : {{ total }} </u>
              </h4>
              <b-row>
                <b-col cols="12">
                  <b-card :title="formListItems.graphicOne ? formListItems.graphicOne.question : 'Loading..'">
                    <apexchart type="bar" height="480" :options="chartOptions1" :series="graphic1Value"></apexchart>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="pt-5 pb-5 mb-5" style="padding-bottom: 90px !important;">
              <b-row>
                <b-col cols="12">
                  <b-card :title="formListItems.graphicTwo ? formListItems.graphicTwo.question : 'Loading..'" id="chart">
                    <apexchart type="pie" :options="chartOptions2" width="520" :series.sync="graphic2Value[0].data"></apexchart>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="pt-5 pb-5 mb-5" style="padding-bottom: 90px !important;">
              <b-row>
                <b-col cols="12">
                  <b-card :title="formListItems.graphicNine ? formListItems.graphicNine.question : 'Loading..'" id="chart">
                    <apexchart type="pie" :options="chartOptions9" width="420" :series="graphic9Value"></apexchart>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="pt-5 pb-5 mb-5" style="padding-bottom: 90px !important;">
              <b-row>
                <b-col cols="12">
                  <b-card title="Please rate your service experience." id="chart">
                    <apexchart type="bar" height="350" :options="chartOptions3" :series="graphic3Value"></apexchart>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="pt-5 pb-5 mb-5" style="padding-bottom: 90px !important;">
              <b-row>
                <b-col cols="12">
                  <b-card :title="formListItems.graphicSeven ? formListItems.graphicSeven.question : 'Loading..'" id="chart">
                    <apexchart type="pie" :options="chartOptions7" width="420" :series="graphic7Value"></apexchart>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>
  </b-overlay>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from 'bootstrap-vue';
import { kFormatter } from '@core/utils/filter';
import moment from 'moment';

import homeStoreModule from './homeStoreModule';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import router from '@/router';

import { $themeColors } from '@themeConfig';

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.getFormList();
  },

  created() {
    // console.log(router.currentRoute);
    // console.log(router.currentRoute.params.crypto);
    // fetch('https://api.ipify.org?format=json')
    //   .then((x) => x.json())
    //   .then(({ ip }) => {
    //     this.term = ip;
    //     // console.log(ip);
    //   });
  },

  watch: {
    total(valData) {
      this.chartOptions3.dataLabels = {
        enabled: true,
        formatter: function(val) {
          return ((val / valData) * 100).toFixed(2) + '%';
        },

        style: {
          fontSize: '12px',
        },
      };

      this.chartOptions3.tooltip = {
        y: {
          formatter: function(val) {
            return val + ' - ' + ((val * 100) / valData).toFixed(2) + '%';
          },
        },
      };

      this.chartOptions2.tooltip = {
        y: {
          formatter: function(val) {
            return ((val * 100) / valData).toFixed(1) + '%';
          },
        },
      };

      this.chartOptions4.tooltip = {
        y: {
          formatter: function(val) {
            return ((val * 100) / valData).toFixed(1) + '%';
          },
        },
      };

      this.chartOptions5.tooltip = {
        y: {
          formatter: function(val) {
            return ((val * 100) / valData).toFixed(1) + '%';
          },
        },
      };

      this.chartOptions6.tooltip = {
        y: {
          formatter: function(val) {
            return ((val * 100) / valData).toFixed(1) + '%';
          },
        },
      };

      this.chartOptions7.tooltip = {
        y: {
          formatter: function(val) {
            return ((val * 100) / valData).toFixed(1) + '%';
          },
        },
      };

      this.chartOptions9.tooltip = {
        y: {
          formatter: function(val) {
            return ((val * 100) / valData).toFixed(1) + '%';
          },
        },
      };

      // return this.total;
    },
  },

  data() {
    const chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      blackColor: '#000',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    };
    let total = 0;

    return {
      total,
      crypto: router.currentRoute.params.crypto,
      chartColors,
      formListItems: [],
      formShow: false,

      graphic1Value: [],
      graphic2Value: [],
      graphic3Value: [],
      graphic4Value: [],
      graphic5Value: [],
      graphic6Value: [],
      graphic7Value: [],
      graphic8Value: [],
      graphic9Value: [],
      chartOptions1: {
        chart: {
          type: 'bar',
          height: 380,
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        colors: ['#1f77b4', '#29a2ae', '#a2951a', '#7f7f7f', '#d347a9', '#8c564b', '#9467bd', '#d62728', '#eb7005', '#1f77b4', '#000'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
          },
          offsetX: 10,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 0.5,
          colors: ['#000'],
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text: '',
          align: 'left',
          floating: true,
        },
        subtitle: {
          text: '',
          align: 'left',
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return '';
              },
            },
          },
        },
      },

      chartOptions2: {
        chart: {
          type: 'pie',
        },
        labels: [],
        responsive: [
          {
            breakpoint: 768,
            options: {
              pie: {
                width: 250,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
      },

      chartOptions3: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,

          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 10,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val.toFixed(2) + '%';
          },

          style: {
            fontSize: '12px',
          },
        },
        xaxis: {
          type: 'string',
          categories: [],
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },

      chartOptions4: {
        chart: {
          type: 'pie',
        },
        labels: [],

        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },

      chartOptions5: {
        chart: {
          type: 'pie',
        },
        labels: [],

        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },

      chartOptions6: {
        chart: {
          type: 'pie',
        },
        labels: [],

        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },

      chartOptions7: {
        chart: {
          type: 'pie',
        },
        labels: [],

        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },

      chartOptions9: {
        chart: {
          type: 'pie',
        },
        labels: [],

        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },

      chartOptions8: {
        chart: {
          type: 'bar',
          height: 380,
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        colors: ['#1f77b4', '#29a2ae', '#a2951a', '#7f7f7f', '#d347a9', '#8c564b', '#9467bd', '#d62728', '#eb7005', '#1f77b4', '#000'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
          },
          offsetX: 10,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 0.5,
          colors: ['#000'],
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text: '',
          align: 'left',
          floating: true,
        },
        subtitle: {
          text: '',
          align: 'left',
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return '';
              },
            },
          },
        },
      },
    };
  },

  methods: {
    kFormatter,

    getFormList() {
      this.formShow = true;
      var tempData1 = [];
      var tempData2 = [];
      var tempData9 = [];
      var tempData7 = [];

      store.dispatch('home/getFormList2024').then((response) => {
        this.formListItems = response.data;

        //one graphic start
        this.formListItems.graphicOne.allInfo.forEach((element) => {
          tempData1.push(element.countValue);
          this.chartOptions1.xaxis.categories.push(element.allInfo);
        });

        this.graphic1Value = [
          {
            data: tempData1,
          },
        ];
        //one graphic finish

        //two graphic start

        this.formListItems.graphicTwo.allInfo.forEach((element) => {
          tempData2.push(element.countValue);

          this.chartOptions2.labels.push(element.allInfo + ' [' + element.countValue + '] ');

          //tempData2
        });

        tempData2.forEach((element) => {
          this.total = this.total + element;
        });

        this.graphic2Value[0] = {
          data: tempData2,
        };

        //two graphic finish

        //three graphic start

        this.formListItems.graphicThree.questions.forEach((element) => {
          this.chartOptions3.xaxis.categories.push(element);
        });
        //orhan
        this.graphic3Value.push({
          name: 'Agree',
          data: [
            this.formListItems.graphicThree.one['1'],
            this.formListItems.graphicThree.two['1'],
            this.formListItems.graphicThree.three['1'],
            this.formListItems.graphicThree.four['1'],
            this.formListItems.graphicThree.five['1'],
            this.formListItems.graphicThree.six['1'],
            this.formListItems.graphicThree.seven['1'],
          ],
        });

        this.graphic3Value.push({
          name: 'Partly Agree',
          data: [
            this.formListItems.graphicThree.one['2'],
            this.formListItems.graphicThree.two['2'],
            this.formListItems.graphicThree.three['2'],
            this.formListItems.graphicThree.four['2'],
            this.formListItems.graphicThree.five['2'],
            this.formListItems.graphicThree.six['2'],
            this.formListItems.graphicThree.seven['2'],
          ],
        });
        this.graphic3Value.push({
          name: 'Disagree',
          data: [
            this.formListItems.graphicThree.one['3'],
            this.formListItems.graphicThree.two['3'],
            this.formListItems.graphicThree.three['3'],
            this.formListItems.graphicThree.four['3'],
            this.formListItems.graphicThree.five['3'],
            this.formListItems.graphicThree.six['3'],
            this.formListItems.graphicThree.seven['3'],
          ],
        });

        this.formListItems.graphicSeven.allInfo.forEach((element) => {
          tempData7.push(element.countValue);

          this.chartOptions7.labels.push(element.allInfo + ' [' + element.countValue + '] ');
        });
        this.graphic7Value = tempData7;

        this.formListItems.graphicNine.allInfo.forEach((element) => {
          tempData9.push(element.countValue);

          this.chartOptions9.labels.push(element.allInfo + ' [' + element.countValue + '] ');
        });
        this.graphic9Value = tempData9;

        //nine graphic finish

        this.formShow = false;
      });
    },

    formClick(formId) {
      router.push({ name: 'form-detail', params: { formId: formId } });
    },

    average(formId) {
      router.push({ name: 'form-average', params: { formId: formId } });
    },
    deviation(formId) {
      router.push({ name: 'form-detail', params: { formId: formId } });
    },
    settings(formId) {
      router.push({ name: 'form-detail', params: { formId: formId } });
    },
  },
};
</script>

<style>
#grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  width: 80%;
  grid-gap: 5px;
}

.acc-item {
  padding: 10%;
  background-color: #292929;
  color: lightgray;
  height: 100%;
  border-radius: 25px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
</style>
